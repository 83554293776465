/* eslint-disable prettier/prettier */
// map.ts
// 换成本地的服务器js文件即可
const scripts: string[] = [
  "https://ditu.gxwztc.cn:9000/bigemap.js/v2.1.0/bigemap.js",
  "https://www.bigemap.com/mapoffline/canvas-layer/canvas-layer.js",
  // 如果有更多的JS要引用 ，也一起放到这个数组中
];

export default new Promise<void>((resolve: () => void) => {
  const link: HTMLLinkElement = document.createElement("link");
  link.rel = "stylesheet";
  //link.async = false;
  // 换成本地的服务器css文件即可
  link.href = "https://ditu.gxwztc.cn:9000/bigemap.js/v2.1.0/bigemap.css";
  document.head.appendChild(link);

  const loads = scripts.map((v: string) => {
    const scriptElement: HTMLScriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.async = false;
    scriptElement.src = v;
    document.head.appendChild(scriptElement);
    return scriptElement;
  });

  if (loads.length > 0) {
    const end = loads[loads.length - 1];
    end.onload = resolve;
  } else {
    resolve(); // 如果没有脚本要加载，直接resolve
  }
// eslint-disable-next-line prettier/prettier
});
