import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeIndex from "../views/HomeIndex.vue";
import IndexView from "../views/IndexView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: IndexView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeIndex,
  },
  {
    path: "/edit",
    name: "edit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EditView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
